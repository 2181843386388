<template>
    <modal ref="modalAgregarServicio" tamano="modal-lg" titulo="Editar servicio" adicional="Guardar" @adicional="editarServicios">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <p class="input-label-top">Taller</p>
                <el-select v-model="model.id_taller" placeholder="Seleccionar taller" size="small" class="w-100">
                    <el-option v-for="item in select_talleres" :key="item.id" :label="item.nombre" :value="item.id" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Configuración</p>
                <el-select v-model="model.id_almacen_etapas_configuraciones_secciones" @change="getSecciones" placeholder="Seleccionar configuración" size="small" class="w-100" >
                    <el-option v-for="item in select_configuraciones" :key="item.id" :label="item.configuracion" :value="item.id" />
                </el-select>
            </div>
            <div class="col-6 my-2">
                <p class="input-label-top">Sección</p>
                <el-select v-model="model.id_seccion" placeholder="Seleccionar seccion" size="small" class="w-100">
                    <el-option v-for="item in select_configuraciones_secciones" :key="item.id" :label="item.seccion" :value="item.id" />
                </el-select>
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Descripción del servicio</p>
                <el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" class="w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            
            nombreTaller: '',
            direccionTaller: '',
            nombreResponsable: '',
            numContacto: '',
            options: [
                {
                    value: 'Option1',
                    label: 'Option1'
                }, 
                {
                    value: 'Option2',
                    label: 'Option2'
                }, 
                {
                    value: 'Option3',
                    label: 'Option3'
                }, 
                {
                    value: 'Option4',
                    label: 'Option4'
                }, 
                {
                    value: 'Option5',
                    label: 'Option5'
                }
            ],
            valueSelect: '',
            model:{
                id_taller: '', 
                id_almacen_etapas_configuraciones_secciones: '',
                id_seccion: '',
                descripcion: '',
            }
        }
    },
    computed: {
        ...mapGetters({
            select_talleres: 'selects/selects/select_talleres',
            select_configuraciones: 'selects/selects/select_configuraciones',
            select_configuraciones_secciones: 'selects/selects/select_configuraciones_secciones',
        })
    },
    methods: {
        ...mapActions({
            Action_get_selects_configuraciones_secciones: 'selects/selects/Action_get_selects_configuraciones_secciones',
            Action_edit_servicio: 'obras/servicios/services/Action_edit_servicio',
        }),
        toggle(data){
            
            if (data) {
                this.model.id_taller = data.id_taller
                this.model.id_almacen_etapas_configuraciones_secciones = data.id_almacen_etapas_configuraciones_secciones
                this.model.descripcion = data.descripcion
                this.model.id_seccion = data.id_seccion
                this.model.id_proyecto = this.$route.params.id_proyecto
                this.model.id_control_piso = this.$route.params.id_control_piso
            }
            this.$refs.modalAgregarServicio.toggle()
        },
        async getSecciones(id_almacen_etapas_configuraciones){
            this.model.id_seccion = ''
            await this.Action_get_selects_configuraciones_secciones({id_almacen_etapas_configuraciones})
        },
        async editarServicios(){
            await this.Action_edit_servicio(this.model)
            this.clean()
            this.$emit('reload')
            this.$refs.modalAgregarServicio.toggle()
        },
        clean(){
            this.model = {
                id_taller: '', 
                id_almacen_etapas_configuraciones_secciones: '',
                id_seccion: '',
                descripcion: '',
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
